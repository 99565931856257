<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            WOW
          </h2>
        </b-link>

        <b-alert variant="success" :show="successMsg != ''" dismissible><div class="success-div">{{ successMsg }}</div></b-alert>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-forgot-password-form mt-2"
        >
          <!-- email -->
          <b-form-group
            label="Email"
            label-for="forgot-password-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="forgot-password-email"
                v-model="userEmail"
                :state="errors.length > 0 ? false:null"
                name="forgot-password-email"
                placeholder=""
                @input="changeEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger" v-show="errorMsg">{{ errorMsg }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            @click="sendResetLink"
          >
            Send reset link
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BAlert
  },
  data() {
    return {
      userEmail: '',
      required,
      email,
      errorMsg: '',
      successMsg: ''
    }
  },
  methods: {
    changeEmail() {
      this.successMsg = ''
      this.errorMsg = ''
    },
    sendResetLink() {
      this.changeEmail()
      axios.get(`login/sendForgetEmail?email=${this.userEmail}`).then(res => {
        const { code, msg } = res
        if (code < 0) {
          this.errorMsg = msg
        } else {
          this.successMsg = msg
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.success-div {
  height: 42px;
  line-height: 42px;
  padding-left: 20px;
}
</style>
